import { useEffect, useState } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { Main } from "./pages/Main";
import Loader from "./components/Loader/Loader";
import GlobalCSS from "./styles/Global.styled.jsx";

function App() {
  const [showLoader, setShowLoader] = useState(true);

  useEffect(() => {
    document.fonts?.ready.then(() => {
      setShowLoader(false);
    });
  });

  return (
    <BrowserRouter>
      <Loader showLoader={showLoader} />
      <GlobalCSS />
      <Routes>
        <Route path='/' element={<Main />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
