import styled, { keyframes } from "styled-components";
import FirstScreenWebp from "../assets/imgs/first_screen_bg.webp";
import FirstScreenPng from "../assets/imgs/first_screen_bg.png";

const blink = keyframes`
  0% {
    opacity: 0.1;
    transform: scale(0.5);
  }
  50% {
    opacity: 1;
    transform: scale(1);
  }
  100% {
    opacity: 0.1;
    transform: scale(0.5);
  }
`;

export const FirstScreenWrapper = styled.div`
  width: 100%;
  background-color: #111012;
  min-height: 100vh;
  background-image: ${(props) =>
    props.isWebpSupported
      ? `url(${FirstScreenWebp})`
      : `url(${FirstScreenPng})`};
  background-repeat: no-repeat;
  background-position: top;
  background-size: contain;
  position: relative;
  overflow: hidden;
  & .star {
    position: absolute;
    animation: 2s linear 0s normal none infinite running ${blink};
  }
`;

export const FirstScreenMain = styled.div`
  padding: 250px 0 60px;
  margin-bottom: 100px;
  @media (max-width: 991.98px) {
    padding-top: 270px;
  }
  @media (max-width: 767.98px) {
    margin-bottom: 0;
  }
  & h2 {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 700;
    font-size: 82px;
    line-height: 114%;
    text-align: center;
    color: #ffffff;
    margin: 0 auto;
    max-width: 1100px;
    position: relative;
    z-index: 2;
    @media (max-width: 767.98px) {
      font-size: 34px;
      line-height: 114%;
      max-width: 400px;
    }
  }
  & h6 {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 180%;
    text-align: center;
    color: #ffffff;
    margin: 30px auto 0;
    position: relative;
    z-index: 2;
    @media (max-width: 767.98px) {
      font-weight: 400;
      font-size: 14px;
      line-height: 180%;
    }
  }
  & .buttons {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 40px;
    position: relative;
    z-index: 2;
    @media (max-width: 477.98px) {
      display: flex;
      justify-content: space-between;
    }
    & button {
      margin: 0 20px;
      @media (max-width: 477.98px) {
        width: 47% !important;
        margin: 0;
      }
    }
  }
`;

export const GlowBackgroundLeft = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 70%;
  height: 100%;
  display: flex;
  align-items: flex-end;
  @media (max-width: 767.98px) {
    bottom: -65px;
  }
  @media (max-width: 477.98px) {
    bottom: -1px;
  }
`;

export const GlowBackgroundRight = styled.div`
  position: absolute;
  bottom: 0;
  right: 0;
  width: 50%;
  height: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  @media (max-width: 767.98px) {
    display: none;
  }
`;

export const OrbitsPlanet = styled.div`
  position: absolute;
  left: 0;
  z-index: 1;
  bottom: -50px;
  max-width: 35%;
  @media (max-width: 477.98px) {
    bottom: -20px;
    max-width: 55%;
  }
  & svg {
    max-width: 100%;
  }
`;

export const PlanetLeft = styled.svg`
  position: absolute;
  left: -2%;
  top: -10%;
  z-index: 1;
  @media (max-width: 767.98px) {
    left: -25%;
    max-width: 300px;
    top: -15%;
  }
`;

export const PlanetRightTop = styled.svg`
  position: absolute;
  right: 15%;
  top: 10%;
  z-index: 1;
  @media (max-width: 1199.98px) {
    max-width: 120px;
  }
  @media (max-width: 767.98px) {
    right: 0%;
  }
`;
export const PlanetRightCenter = styled.svg`
  position: absolute;
  right: 5%;
  top: 40%;
  z-index: 1;
  @media (max-width: 991.98px) {
    right: 0;
    top: 35%;
  }
  @media (max-width: 767.98px) {
    display: none;
  }
`;

export const PlanetRightBottom = styled.svg`
  position: absolute;
  right: 8%;
  bottom: 5%;
  z-index: 1;
  @media (max-width: 1199.98px) {
    max-width: 180px;
    right: 5%;
    bottom: 2%;
  }
  @media (max-width: 991.98px) {
    bottom: 0;
    right: 3%;
  }
  @media (max-width: 767.98px) {
    display: none;
  }
`;
