import { PlanetRightBottom } from "../../../styles/FirstScreen.styled";
import react from "./react.png";

export default function RightBottomPlanet({ className }) {
  const styles = {
    animation: "25s linear 0s normal none infinite running rot",
    transformOrigin: "center center",
  };
  return (
    <PlanetRightBottom
      className={className}
      width='303'
      height='303'
      viewBox='0 0 303 303'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'>
      <circle
        cx='151.418'
        cy='151.418'
        r='123.864'
        transform='rotate(14.7618 151.418 151.418)'
        fill='url(#paint0_radial_134_89)'
      />
      <defs>
        <radialGradient
          id='paint0_radial_134_89'
          cx='0'
          cy='0'
          r='1'
          gradientUnits='userSpaceOnUse'
          gradientTransform='translate(151.418 80.5031) rotate(102.297) scale(177.579 203.692)'>
          <stop offset='0.0793565' stopColor='#3B64DC' />
          <stop offset='0.558523' stopColor='#5E80E5' />
          <stop offset='1' stopColor='#DFBFFF' />
        </radialGradient>
        <radialGradient
          id='paint1_radial_134_89'
          cx='0'
          cy='0'
          r='1'
          gradientUnits='userSpaceOnUse'
          gradientTransform='translate(151.418 80.5031) rotate(102.297) scale(177.579 203.692)'>
          <stop offset='0.0793565' stopColor='#3B64DC' stopOpacity='0.22' />
          <stop offset='0.558523' stopColor='#5E80E5' stopOpacity='0.69' />
          <stop offset='1' stopColor='#DFBFFF' />
        </radialGradient>
      </defs>{" "}
      <image
        x='34%'
        y='33%'
        width='100px'
        height='100px'
        style={styles}
        xlinkHref={react}></image>
    </PlanetRightBottom>
  );
}
