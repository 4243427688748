import "react-lazy-load-image-component/src/effects/blur.css";
import { isWebpSupported } from "react-image-webp/dist/utils";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { Button } from "../Button/Button";
import { checkWepbSupportImage, setImageSize } from "../../utils/utils";
import Telegram from "../../assets/imgs/icons/telegram";
import Twitter from "../../assets/imgs/icons/twitter";
import RightBottomPlanet from "../../assets/imgs/planets/RightBottomPlanet";
import LeftPlanet from "../../assets/imgs/planets/LeftPlanet";
import RightCenterPlanet from "../../assets/imgs/planets/RightCanterPlanet";
import RightTopPlanet from "../../assets/imgs/planets/RightTopPlanet";
import Star from "../../assets/imgs/planets/Star";
import LeftBlurWebP from "../../assets/imgs/first_screen_glow_bg_left.webp";
import LeftBlurPng from "../../assets/imgs/first_screen_glow_bg_left.png";
import LeftBlurWebPMin from "../../assets/imgs/first_screen_glow_bg_left_min.webp";
import LeftBlurPngMin from "../../assets/imgs/first_screen_glow_bg_left_min.png";
import RightBlurWebP from "../../assets/imgs/first_screen_glow_bg_right.webp";
import RightBlurPngMin from "../../assets/imgs/first_screen_glow_bg_right_min.png";
import RightBlurWebPMin from "../../assets/imgs/first_screen_glow_bg_right_min.webp";
import RightBlurPng from "../../assets/imgs/first_screen_glow_bg_right.png";
import orbitWebp from "../../assets/imgs/planets/orbits.webp";
import orbitWebpMin from "../../assets/imgs/planets/orbits_min.webp";
import orbitPng from "../../assets/imgs/planets/orbits.png";
import orbitPngMin from "../../assets/imgs/planets/orbits_min.png";
import {
  FirstScreenMain,
  FirstScreenWrapper,
  GlowBackgroundLeft,
  GlowBackgroundRight,
  OrbitsPlanet,
} from "../../styles/FirstScreen.styled";

export const FirstScreen = () => {
  return (
    <FirstScreenWrapper isWebpSupported={isWebpSupported}>
      <div className='container'>
        <FirstScreenMain>
          <h2>Bootstrapping Ideas with Innovative Fundraising</h2>
          <h6>
            Earn passive income while having a private access deals to early
            stage startups.
          </h6>
          <div className='buttons'>
            <Button
              icon={<Twitter />}
              datatestid='tw-button'
              text={"TWITTER"}
              color='light_blue'
              prop_styles={{ width: "180px" }}
              onClick={() =>
                window.open("https://twitter.com/EvmosPad", "_blank")
              }
            />
            <Button
              icon={<Telegram />}
              text={"TELEGRAM"}
              datatestid='tg-button'
              color='blue'
              prop_styles={{ width: "180px" }}
              onClick={() =>
                window.open("https://t.me/EvmosPadCommunity", "_blank")
              }
            />
          </div>
        </FirstScreenMain>
      </div>
      <LeftPlanet />
      <RightTopPlanet />
      <RightCenterPlanet />
      <RightBottomPlanet />
      <OrbitsPlanet>
        <LazyLoadImage
          alt={"orbit"}
          src={checkWepbSupportImage(
            setImageSize(orbitWebp, orbitWebpMin),
            setImageSize(orbitPng, orbitPngMin),
            isWebpSupported
          )}
          effect='blur'
          threshold='300'
        />
      </OrbitsPlanet>
      <GlowBackgroundLeft>
        <LazyLoadImage
          alt={"orbit"}
          src={checkWepbSupportImage(
            setImageSize(LeftBlurWebP, LeftBlurWebPMin),
            setImageSize(LeftBlurPng, LeftBlurPngMin),
            isWebpSupported
          )}
          effect='blur'
          threshold='500'
        />
      </GlowBackgroundLeft>
      <GlowBackgroundRight>
        <LazyLoadImage
          alt={"orbit"}
          src={checkWepbSupportImage(
            setImageSize(RightBlurWebP, RightBlurWebPMin),
            setImageSize(RightBlurPng, RightBlurPngMin),
            isWebpSupported
          )}
          effect='blur'
          threshold='500'
        />
      </GlowBackgroundRight>
      <Star size={30} duration={20} left={"50%"} top={"50%"} />
      <Star size={60} duration={14} left={"45%"} top={"90%"} />
      <Star size={80} duration={17} left={"96%"} top={"20%"} />
      <Star size={30} duration={15} left={"50%"} top={"50%"} />
      <Star size={50} duration={13} left={"30%"} top={"30%"} />
      <Star size={40} duration={11} left={"73%"} top={"60%"} />
      <Star size={70} duration={12} left={"70%"} top={"90%"} />
    </FirstScreenWrapper>
  );
};
