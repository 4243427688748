import {
  Circle1,
  Circle2,
  Circle3,
  LoaderDot1,
  LoaderDot2,
  LoaderDot3,
  LoaderDot4,
  LoaderGalaxy,
  LoaderOrbit1,
  LoaderOrbit2,
  LoaderOrbit3,
  LoaderOrbit4,
  LoaderPos1,
  LoaderPos2,
  LoaderPos3,
  LoaderPos4,
  LoaderUniverse,
} from "../../styles/Loader.styled";

export default function Loader({ showLoader }) {
  return (
    <LoaderUniverse data-testid={"loader"} showLoader={showLoader}>
      <LoaderGalaxy>
        <Circle1 />
        <Circle2 />
        <Circle3 />
        <LoaderOrbit1>
          <LoaderPos1>
            <LoaderDot1 />
          </LoaderPos1>
        </LoaderOrbit1>
        <LoaderOrbit2>
          <LoaderPos2>
            <LoaderDot2 />
          </LoaderPos2>
        </LoaderOrbit2>
        <LoaderOrbit3>
          <LoaderPos3>
            <LoaderDot3 />
          </LoaderPos3>
        </LoaderOrbit3>
        <LoaderOrbit4>
          <LoaderPos4>
            <LoaderDot4 />
          </LoaderPos4>
        </LoaderOrbit4>
      </LoaderGalaxy>
    </LoaderUniverse>
  );
}
