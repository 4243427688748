import { PlanetRightCenter } from "../../../styles/FirstScreen.styled";

export default function RightCenterPlanet() {
  const styles = {
    animation: "18s linear 0s normal none infinite running sway",
    transformOrigin: "center center",
  };
  return (
    <PlanetRightCenter
      style={styles}
      width='111'
      height='111'
      viewBox='0 0 111 111'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'>
      <circle
        cx='55.7109'
        cy='55.7114'
        r='43.1377'
        transform='rotate(-19.3627 55.7109 55.7114)'
        fill='url(#paint0_radial_451_159)'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M66.578 45.5628C67.0895 46.0703 67.9209 46.0069 68.3046 45.436C68.6882 44.9919 68.6243 44.2941 68.1767 43.9135C63.9561 39.9172 60.1832 37.8873 53.9163 38.2044C47.3296 38.5216 41.5103 41.9471 41.894 49.1786C42.2138 55.5855 48.4807 57.9325 53.9802 60.0259C58.2008 61.6117 61.9098 63.0073 62.1016 65.9887C62.2934 69.2873 58.9042 71.2538 55.9626 71.4441C50.3991 71.7613 47.1378 69.7948 43.4927 66.3059C43.0451 65.8619 42.3417 65.8619 41.894 66.2425C41.3825 66.6865 41.3825 67.4477 41.8301 67.9552C45.9228 72.015 50.1433 74.0449 56.2823 73.7277C62.4213 73.2837 69.9672 69.8582 69.5835 62.6267C69.2638 56.3467 63.2527 53.9996 57.8171 51.9697C53.3407 50.257 49.1841 48.6711 48.9923 45.0554C48.8644 42.4546 51.9978 40.5515 54.236 40.4246C59.6716 40.1709 63.0608 42.0739 66.578 45.5628ZM67.2816 62.7536C67.2816 65.5448 65.7468 67.9553 63.4447 69.3508L63.3168 69.2874C64.1481 68.1456 64.276 66.6866 64.276 65.291C64.276 61.1678 59.7357 59.5819 54.9396 57.8692C49.6319 55.9662 44.0684 53.9997 44.0684 48.4175C44.0684 45.8801 45.2195 43.1524 47.4577 41.8837L47.5216 42.0106C46.9461 43.2158 46.6903 44.548 46.6903 45.8801C46.6903 50.4474 51.7422 52.287 56.858 54.19C62.1018 56.093 67.2816 57.9961 67.2816 62.7536Z'
        fill='white'
      />
      <circle
        cx='55.7109'
        cy='55.7117'
        r='43.1377'
        transform='rotate(-19.3627 55.7109 55.7117)'
        fill='url(#paint1_radial_451_159)'
      />
      <defs>
        <radialGradient
          id='paint0_radial_451_159'
          cx='0'
          cy='0'
          r='1'
          gradientUnits='userSpaceOnUse'
          gradientTransform='translate(73.5226 37.0647) rotate(138.455) scale(81.7873)'>
          <stop stopColor='#44423D' />
          <stop offset='0.678315' stopColor='#1D1B1F' />
          <stop offset='1' stopColor='#80ABEC' />
        </radialGradient>
        <radialGradient
          id='paint1_radial_451_159'
          cx='0'
          cy='0'
          r='1'
          gradientUnits='userSpaceOnUse'
          gradientTransform='translate(73.5226 37.0651) rotate(138.455) scale(81.7873)'>
          <stop offset='0.141545' stopColor='#44423D' stopOpacity='0.49' />
          <stop offset='0.678315' stopColor='#1D1B1F' stopOpacity='0.97' />
          <stop offset='1' stopColor='#80ABEC' />
        </radialGradient>
      </defs>
    </PlanetRightCenter>
  );
}
