import { createGlobalStyle } from "styled-components";

export default createGlobalStyle`
html,
body,
#root {
  min-height: 100%;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

ul[class],
ol[class] {
  padding: 0;
  margin: 0;
  list-style: none;
}

body,
h1,
h2,
h3,
h4,
h5,
h6,
p,
li,
figure,
figcaption,
blockquote,
dl,
dd {
  margin: 0;
}

/* Выставляем основные настройки по-умолчанию для body */
body {
  min-height: 100vh;
  scroll-behavior: smooth;
  text-rendering: optimizeSpeed;
  line-height: 1.5;
  background: #fff;
  overflow-x: hidden;
}

a {
  text-decoration: none;
  cursor: pointer;
}

a:not([class]) {
  -webkit-text-decoration-skip: ink;
  text-decoration-skip-ink: auto;
}

img {
  max-width: 100%;
  display: block;
}

article > * + * {
  margin-top: 1em;
}

input,
button,
textarea,
select {
  font: inherit;
}

.container {
  padding: 0 15px;
}

@media (min-width: 477.98px) {
  .container {
    max-width: 100%;
    margin: auto;
    padding: 0 15px;
  }
}

@media (min-width: 767.98px) {
  .container {
    max-width: 720px;
    padding: 0;
  }
}

@media (min-width: 991.98px) {
  .container {
    max-width: 960px;
    padding: 0;
  }
}

@media (min-width: 1199.98px) {
  .container {
    max-width: 1140px;
    padding: 0;
  }
}

@media (min-width: 1399.98px) {
  .container {
    max-width: 1320px;
    padding: 0;
  }
}

@media (min-width: 1919.98px) {
  .container {
    max-width: 1730px;
    margin: 0 auto;
    padding: 0;
  }
}






@-webkit-keyframes rot {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@keyframes rot {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@-webkit-keyframes sway {
  0% {
    transform: rotate(0deg);
  }
  25% {
    transform: rotate(30deg);
  }
  50% {
    transform: rotate(0deg);
  }
  75% {
    transform: rotate(-30deg);
  }
  100% {
    transform: rotate(0);
  }
}

@keyframes sway {
  0% {
    transform: rotate(0deg);
  }
  25% {
    transform: rotate(30deg);
  }
  50% {
    transform: rotate(0deg);
  }
  75% {
    transform: rotate(-30deg);
  }
  100% {
    transform: rotate(0);
  }
}

`;
