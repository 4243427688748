import styled from "styled-components";

export const ButtonMain = styled.button`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 12px 20px;
  border-radius: 12px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  height: 48px;
  text-transform: uppercase;
  color: #ffffff;
  margin: 0 10px;
  border: none;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  background: ${(props) =>
    props.color === "red"
      ? "linear-gradient(253.17deg, #df2849 38.39%, #fd5f7b 82.57%)"
      : props.color === "blue"
      ? "linear-gradient(264.91deg, #0868f7 29.47%, #70bbff 100%)"
      : "linear-gradient(265.68deg, #00a5ec 57.72%, #9bdefb 100%)"};
  opacity: ${(props) => (!props.active ? 0.7 : 1)};
  pointer-events: ${(props) => (!props.active ? "none" : "auto")};
  :hover {
    ::after {
      background: ${(props) =>
        props.color === "red"
          ? "#ce1e3e"
          : props.color === "blue"
          ? "#035ee7"
          : "#0691cd"};
      right: 0;
      transition: 0.2s;
    }
  }
  ::after {
    content: "";
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 1;
    right: -100%;
    transition: 0.2s;
    border-radius: 12px;
  }
  & span {
    z-index: 2;
    display: flex;
    align-items: center;
    line-height: 0;
    & svg {
      margin-right: 8px;
    }
  }
`;
