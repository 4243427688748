import { ButtonMain } from "../../styles/Button.styled";

export const Button = ({
  text,
  color,
  onClick,
  prop_styles,
  icon,
  active = true,
  datatestid,
}) => {
  return (
    <ButtonMain
      style={prop_styles}
      data-testid={datatestid}
      onClick={onClick}
      active={active}
      color={color}>
      <span>
        {icon}
        {text}
      </span>
    </ButtonMain>
  );
};
