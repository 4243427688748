export const checkWepbSupportImage = (imgWebp, imgPng, isSupport) => {
  return isSupport ? imgWebp : imgPng;
};

export const setImageSize = (img, minimg) => {
  if (window.innerWidth < 768) {
    return minimg;
  } else {
    return img;
  }
};
