import logo from "../../assets/imgs/logo.svg";
import { Button } from "../Button/Button";
import { useState } from "react";
import { MobileMenu } from "./MobileMenu";
import {
  Logo,
  MenuLink,
  MenuMain,
  MenuRight,
  MenuWrapper,
  MobileMenuBtn,
} from "../../styles/Menu.styled";
import { MenuDropdown } from "./MenuDropdown";

export const Menu = () => {
  const [openMenu, setOpenMenu] = useState(false);

  const toggleMenu = () => {
    setOpenMenu(!openMenu);
  };

  return (
    <MenuWrapper>
      <div className='container'>
        <MenuMain>
          <Logo to='/'>
            <img src={logo} alt='logo' />
          </Logo>
          <MenuRight>
            <MenuLink to='/' active={{ value: false }}>
              Whitepaper
            </MenuLink>
            <MenuLink to='/' active={{ value: false }}>
              App
            </MenuLink>
            <Button text='Connect Wallet' active={false} color='red' />
            <MenuDropdown />
          </MenuRight>
          <MobileMenuBtn
            data-testid='mobile-menu-button'
            openMenu={openMenu}
            onClick={toggleMenu}>
            <span></span>
            <span></span>
          </MobileMenuBtn>
        </MenuMain>
      </div>
      <MobileMenu openMenu={openMenu} />
    </MenuWrapper>
  );
};
