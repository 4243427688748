export default function Twitter() {
  return (
    <svg
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M23.6435 5.08014C22.8085 5.45014 21.9115 5.70014 20.9685 5.81314C21.9415 5.23094 22.6694 4.31461 23.0165 3.23514C22.1023 3.77813 21.1018 4.16034 20.0585 4.36514C19.3569 3.61601 18.4276 3.11947 17.4148 2.95262C16.4021 2.78577 15.3626 2.95793 14.4577 3.4424C13.5529 3.92686 12.8333 4.69651 12.4107 5.63185C11.988 6.56719 11.886 7.61589 12.1205 8.61514C10.2682 8.52214 8.45613 8.04069 6.80192 7.20205C5.14772 6.3634 3.68834 5.1863 2.5185 3.74714C2.1185 4.43714 1.8885 5.23714 1.8885 6.08914C1.88805 6.85614 2.07693 7.61139 2.43838 8.28788C2.79982 8.96437 3.32266 9.54118 3.9605 9.96714C3.22078 9.94361 2.49737 9.74373 1.8505 9.38414V9.44414C1.85043 10.5199 2.22253 11.5625 2.90368 12.3951C3.58483 13.2278 4.53307 13.7991 5.5875 14.0121C4.90128 14.1979 4.18184 14.2252 3.4835 14.0921C3.781 15.0178 4.3605 15.8272 5.14087 16.4071C5.92124 16.987 6.86342 17.3083 7.8355 17.3261C6.18533 18.6216 4.14739 19.3242 2.0495 19.3211C1.67788 19.3212 1.30658 19.2995 0.9375 19.2561C3.06698 20.6253 5.54584 21.352 8.0775 21.3491C16.6475 21.3491 21.3325 14.2511 21.3325 8.09514C21.3325 7.89514 21.3275 7.69314 21.3185 7.49314C22.2298 6.83412 23.0164 6.01804 23.6415 5.08314L23.6435 5.08014Z'
        fill='white'
      />
    </svg>
  );
}
