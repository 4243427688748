export default function Star({ duration, size, left, top }) {
  return (
    <svg
      className='star'
      style={{ animationDuration: `${duration}s`, left: left, top: top }}
      width={size}
      height={size}
      viewBox='0 0 102 102'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'>
      <circle
        cx='51.1498'
        cy='51.1498'
        r='50.6212'
        transform='rotate(-60 51.1498 51.1498)'
        fill='url(#paint0_radial_455_165)'
      />
      <defs>
        <radialGradient
          id='paint0_radial_455_165'
          cx='0'
          cy='0'
          r='1'
          gradientUnits='userSpaceOnUse'
          gradientTransform='translate(51.1498 51.1498) rotate(90) scale(50.6212)'>
          <stop stopColor='#FFF3B6' stopOpacity='0.8' />
          <stop offset='0.81894' stopColor='#F9C235' stopOpacity='0.1' />
          <stop offset='1' stopColor='#F9C235' stopOpacity='0' />
        </radialGradient>
      </defs>
    </svg>
  );
}
