import styled from "styled-components";
import { Link } from "react-router-dom";

export const MenuWrapper = styled.div`
  position: absolute;
  top: 0;
  z-index: 5;
  width: 100%;
  height: 100px;
`;

export const MenuMain = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  height: 100px;
`;

export const MenuRight = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media (max-width: 768px) {
    display: none;
  }
`;

export const MenuLink = styled(Link)`
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 164.02%;
  text-transform: uppercase;
  color: ${(props) => (!props.active?.value ? "#b9b9b9" : "#ffffff")};
  margin: 0 20px;
  transition: 0.2s;
  pointer-events: ${(props) => (!props.active?.value ? "none" : "auto")};
  &:hover {
    color: #b9b9b9;
    transition: 0.2s;
  }
`;

export const Logo = styled(Link)`
  z-index: 1;
  & img {
    width: 200px;
    height: 28px;
  }
`;

export const MobileMenuBtn = styled.button`
  display: none;
  background: #ffffff;
  border-radius: 8px;
  width: 34px;
  height: 34px;
  border: none;
  outline: none;
  flex-direction: column;
  align-items: flex-end;
  justify-content: space-evenly;
  padding: 5px;
  z-index: 3;
  position: relative;
  position: fixed;
  right: 15px;
  & span {
    height: 3px;
    background: #000;
    width: 24px;
    border-radius: 10px;
    display: block;
    transform: ${(props) => (props.openMenu ? "rotate(45deg)" : "rotate(0)")};
    transition: 0.2s;
    position: ${(props) => (props.openMenu ? "absolute" : "relative")};
  }
  & span:nth-child(2) {
    transform: ${(props) => (props.openMenu ? "rotate(135deg)" : "rotate(0)")};
    width: ${(props) => (props.openMenu ? "24px" : "16px")};
    transition: 0.2s;
    position: ${(props) => (props.openMenu ? "absolute" : "relative")};
  }
  @media (max-width: 767.98px) {
    display: flex;
  }
`;

export const MobileMenuWrapper = styled.div`
  position: fixed;
  background: #111012;
  width: 100%;
  height: 100%;
  transition: 0.2s;
  left: ${(props) => (props.openMenu ? "0" : "-100%")};
  z-index: 2;
  top: 0;
  min-height: -webkit-fit-content;
  min-height: -moz-fit-content;
  min-height: fit-content;
  & hr {
    color: #595959;
  }
  & .container {
    height: 100%;
  }
`;

export const MobileLogo = styled(Logo)`
  & img {
    margin: 36px 0;
    width: 200px;
    height: 28px;
  }
`;

export const MobileMenuContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: calc(100vh - 150px);
  overflow: scroll;
  ::-webkit-scrollbar {
    width: 0;
    height: 0;
    opacity: 0;
  }
  ::-moz-scrollbar {
    width: 0;
    height: 0;
    opacity: 0;
  }
`;

export const MobileMenuTop = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 25px;
`;

export const LinkMobileMenuTop = styled(Link)`
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 164.02%;
  text-transform: uppercase;
  color: ${(props) => (!props.active?.value ? "#b9b9b9" : "#ffffff")};
  pointer-events: ${(props) => (!props.active?.value ? "none" : "auto")};
  margin-top: 25px;
`;

export const MobileMenuBottom = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  & a {
    display: flex;
    margin: 10px 0;
    & img {
      width: 42px;
      height: 42px;
      margin-right: 10px;
    }
    & div {
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      & h5 {
        font-family: "Poppins";
        font-style: normal;
        font-weight: 600;
        font-size: 18px;
        line-height: 114%;
        display: flex;
        align-items: center;
        text-transform: uppercase;
        color: #ffffff;
      }
      & span {
        font-family: "Poppins";
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 140%;
        display: flex;
        align-items: center;
        color: #c4c4c4;
      }
    }
  }
`;
