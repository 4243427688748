export default function Telegram() {
  return (
    <svg
      width='26'
      height='26'
      viewBox='0 0 26 26'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M22.387 3.09818L3.17948 10.5049C1.86865 11.0314 1.87623 11.7627 2.93898 12.0888L7.87032 13.6271L19.28 6.42835C19.8195 6.1001 20.3124 6.27668 19.9072 6.63635L10.6631 14.9791H10.661L10.6631 14.9802L10.323 20.0632C10.8213 20.0632 11.0412 19.8346 11.3207 19.5648L13.716 17.2357L18.6982 20.9158C19.6169 21.4217 20.2766 21.1617 20.5052 20.0653L23.7758 4.65168C24.1106 3.30943 23.2634 2.70168 22.387 3.09818Z'
        fill='white'
      />
    </svg>
  );
}
