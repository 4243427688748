export default function dropdown() {
  return (
    <svg
      width='21'
      height='20'
      viewBox='0 0 21 20'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'>
      <rect
        x='0.09375'
        y='0.037735'
        width='8.06175'
        height='7.71124'
        rx='2'
        fill='white'
      />
      <rect
        x='12.1875'
        y='0.037735'
        width='8.06175'
        height='7.71124'
        rx='2'
        fill='white'
      />
      <rect
        x='12.1875'
        y='11.6045'
        width='8.06175'
        height='7.71124'
        rx='2'
        fill='white'
      />
      <rect
        x='0.09375'
        y='11.6045'
        width='8.06175'
        height='7.71124'
        rx='2'
        fill='white'
      />
    </svg>
  );
}
