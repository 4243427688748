import styled from "styled-components";

export const MenuDropdownWrapper = styled.div`
  position: relative;
`;

export const MenuDropdownBtn = styled.button`
  border: 1px solid #515151;
  box-sizing: border-box;
  border-radius: 12px;
  cursor: pointer;
  background: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50px;
  width: 50px;
  margin: 20px 0 20px 20px;
  transition: 0.2s;
  :hover  {
    background: #ffffff;
    transition: 0.2s;
  }
  & svg {
    & rect {
      transition: 0.2s;
    }
  }
  :hover {
    & svg {
      & rect {
        fill: #111;
        transition: 0.2s;
      }
    }
  }
`;

export const DropdownContent = styled.div`
  padding: 0 20px;
  position: absolute;
  box-shadow: 4px 10px 60px rgba(0, 0, 0, 0.6);
  background: #1e1e1f;
  border-radius: 12px;
  right: 0;
  margin-top: -10px;
  :after {
    content: "";
    position: absolute;
    width: 15px;
    height: 15px;
    background: #1e1e1f;
    top: -5px;
    right: 20px;
    transform: rotate(45deg);
    border-radius: 2px;
  }
  & img {
    max-width: 42px;
    max-height: 42px;
    margin-right: 20px;
  }
  & a {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin: 20px 0;
  }
  & div {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    height: 35px;
    & h5 {
      font-family: "Poppins";
      font-style: normal;
      font-weight: 600;
      font-size: 12px;
      line-height: 114%;
      display: flex;
      align-items: center;
      text-transform: uppercase;
      color: #ffffff;
      margin: 0;
      white-space: nowrap;
    }
    & span {
      font-family: "Poppins";
      font-style: normal;
      font-weight: 400;
      font-size: 8px;
      line-height: 140%;
      color: #c4c4c4;
      white-space: nowrap;
    }
  }
`;
