import { FirstScreen } from "../components/FirstScreen/FirstScreen";
import { Menu } from "../components/Menu/Menu";

export const Main = () => {
  return (
    <>
      <Menu />
      <FirstScreen />
    </>
  );
};
