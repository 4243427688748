import { useState } from "react";
import DropdownImage from "../../assets/imgs/dropdown";
import evmos from "../../assets/imgs/icons/evmos.png";
import validators from "../../assets/imgs/icons/validators.png";
import {
  DropdownContent,
  MenuDropdownBtn,
  MenuDropdownWrapper,
} from "../../styles/MenuDropdown.styled";

export const MenuDropdown = () => {
  const [showDropdown, setShowDropdown] = useState(false);

  return (
    <MenuDropdownWrapper
      data-testid='dropdown-wrapper'
      onMouseLeave={() => {
        setShowDropdown(false);
      }}>
      <MenuDropdownBtn
        data-testid='dropdown-button'
        onMouseEnter={() => {
          setShowDropdown(true);
        }}>
        <DropdownImage />
      </MenuDropdownBtn>
      {showDropdown && (
        <DropdownContent data-testid='dropdown-content'>
          <a href='https://evm.evmos.org/' target='_blank' rel='noreferrer'>
            <img src={evmos} alt='evmos' />
            <div>
              <h5>Evmos Explorer</h5>
              <span>Cosmos explorer</span>
            </div>
          </a>
          <a
            href='https://docs.evmos.org/validators/overview.html'
            target='_blank'
            rel='noreferrer'>
            <img src={validators} alt='validators' />
            <div>
              <h5>Validators</h5>
              <span>Validator Details</span>
            </div>
          </a>
        </DropdownContent>
      )}
    </MenuDropdownWrapper>
  );
};
