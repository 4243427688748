import styled, { keyframes } from "styled-components";

const orbit = keyframes`
  0% {
    transform: rotateZ(0deg);
  }
  100% {
    transform: rotateZ(-360deg);
  }
`;

const invert = keyframes`
  0% {
    transform: rotateX(-90deg) rotateY(360deg) rotateZ(0deg);
  }
  100% {
    transform: rotateX(-90deg) rotateY(0deg) rotateZ(0deg);
  }
`;

const spinner = keyframes`
  0% {
    -webkit-transform: rotate3d(1, 0, 1, 0deg);
  }
  50% {
    -webkit-transform: rotate3d(1, 0, 1, 180deg);
  }
  100% {
    -webkit-transform: rotate3d(1, 0, 1, 360deg);
  }
`;

const spinner2 = keyframes`
  0% {
    -webkit-transform: rotate3d(0, 1, 1, 0deg);
  }
  50% {
    -webkit-transform: rotate3d(0, 1, 1, 180deg);
  }
  100% {
    -webkit-transform: rotate3d(0, 1, 1, 360deg);
  }
`;

const spinner3 = keyframes`
  0% {
    -webkit-transform: rotate3d(1, 1, 0, 0deg);
  }
  50% {
    -webkit-transform: rotate3d(1, 1, 0, 180deg);
  }
  100% {
    -webkit-transform: rotate3d(1, 1, 0, 360deg);
  }
`;

export const LoaderUniverse = styled.div`
  z-index: 999;
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: #111012;
  opacity: ${(props) => (props.showLoader ? 1 : 0)};
  transition: 0.2s;
  pointer-events: ${(props) => (props.showLoader ? "auto" : "none")};
`;

export const LoaderGalaxy = styled.div`
  transform: rotateX(75deg);
  transform-style: preserve-3d;
  position: relative;
  width: 100%;
  height: 100%;
`;

export const LoaderOrbit = styled.div`
  transform-style: preserve-3d;
  position: absolute;
  top: 50%;
  left: 50%;
  animation-name: ${orbit};
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  width: 8em;
  height: 8em;
  margin-top: -4em;
  margin-left: -4em;
  border-radius: 50%;
  -webkit-animation-duration: 3s;
  animation-duration: 3s;
`;

export const LoaderOrbit1 = styled(LoaderOrbit)`
  animation-delay: 0s;
`;
export const LoaderOrbit2 = styled(LoaderOrbit)`
  animation-delay: -1s;
`;
export const LoaderOrbit3 = styled(LoaderOrbit)`
  animation-delay: -2s;
`;
export const LoaderOrbit4 = styled(LoaderOrbit)`
  animation-delay: -2s;
`;
export const LoaderPos = styled.div`
  position: absolute;
  width: 2em;
  height: 2em;
  margin-left: -1em;
  margin-top: -1em;
  animation-name: ${invert};
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  left: 50%;
  top: -1px;
  animation-duration: 3s;
`;

export const LoaderPos1 = styled(LoaderPos)`
  animation-delay: 0s;
`;
export const LoaderPos2 = styled(LoaderPos)`
  animation-delay: -1s;
`;
export const LoaderPos3 = styled(LoaderPos)`
  animation-delay: -2s;
`;
export const LoaderPos4 = styled(LoaderPos)`
  animation-delay: -3s;
`;
export const LoaderDot = styled.div`
  background-color: #eee;
  width: 0.5em;
  height: 0.5em;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -0.25em;
  margin-left: -0.25em;
  border-radius: 50%;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  font-size: 0.2em;
`;
export const LoaderDot1 = styled(LoaderDot)`
  animation-delay: 0s;
`;
export const LoaderDot2 = styled(LoaderDot)`
  animation-delay: -1s;
`;
export const LoaderDot3 = styled(LoaderDot)`
  animation-delay: -2s;
`;
export const LoaderDot4 = styled(LoaderDot)`
  animation-delay: -3s;
`;

export const Circle1 = styled.div`
  font-size: 6em;
  border-radius: 50%;
  border: 1px solid #eee;
  width: 1em;
  height: 1em;
  margin-top: -0.5em;
  margin-left: -0.5em;
  animation: ${spinner} 1.5s infinite ease;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: rotateX(-75deg);
`;

export const Circle2 = styled.div`
  font-size: 5em;
  border-radius: 50%;
  border: 1px solid #eee;
  width: 1em;
  height: 1em;
  margin-top: -0.5em;
  margin-left: -0.5em;
  animation: ${spinner2} 2s infinite ease;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: rotateX(-75deg);
`;

export const Circle3 = styled.div`
  border-radius: 50%;
  border: 1px solid #eee;
  width: 1em;
  height: 1em;
  margin-top: -0.5em;
  margin-left: -0.5em;
  animation: ${spinner3} 2.5s infinite ease;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: rotateX(-75deg);
  font-size: 4em;
`;
