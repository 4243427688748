import { PlanetLeft } from "../../../styles/FirstScreen.styled";

export default function LeftPlanet() {
  const styles = {
    animation: "18s linear 0s normal none infinite running sway",
    transformOrigin: "center center",
  };
  return (
    <PlanetLeft
      style={styles}
      width='382'
      height='436'
      viewBox='0 0 382 436'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'>
      <circle
        cx='184.774'
        cy='236.774'
        r='48.3583'
        transform='rotate(52.6872 184.774 236.774)'
        fill='url(#paint0_radial_410_7)'
      />
      <ellipse
        opacity='0.6'
        cx='191'
        cy='218'
        rx='191'
        ry='218'
        fill='url(#paint1_radial_410_7)'
      />
      <defs>
        <radialGradient
          id='paint0_radial_410_7'
          cx='0'
          cy='0'
          r='1'
          gradientUnits='userSpaceOnUse'
          gradientTransform='translate(204.741 215.871) rotate(144.073) scale(68.4324)'>
          <stop offset='0.609399' stopColor='#120C39' />
          <stop offset='1' stopColor='#31209B' />
        </radialGradient>
        <radialGradient
          id='paint1_radial_410_7'
          cx='0'
          cy='0'
          r='1'
          gradientUnits='userSpaceOnUse'
          gradientTransform='translate(191 218) rotate(90) scale(218 191)'>
          <stop stopColor='#5B36C4' stopOpacity='0.79' />
          <stop offset='1' stopColor='#3B25C5' stopOpacity='0' />
        </radialGradient>
      </defs>
    </PlanetLeft>
  );
}
