import logo from "../../assets/imgs/logo.svg";
import evmos from "../../assets/imgs/icons/evmos.png";
import validators from "../../assets/imgs/icons/validators.png";
import { Button } from "../Button/Button";
import {
  LinkMobileMenuTop,
  MobileLogo,
  MobileMenuBottom,
  MobileMenuContent,
  MobileMenuTop,
  MobileMenuWrapper,
} from "../../styles/Menu.styled";

export const MobileMenu = ({ openMenu }) => {
  return (
    <MobileMenuWrapper data-testid='mobile-menu' openMenu={openMenu}>
      <div className='container'>
        <MobileLogo to='/'>
          <img src={logo} alt='logo' />
        </MobileLogo>
        <hr size='1' />
        <MobileMenuContent>
          <MobileMenuTop>
            <LinkMobileMenuTop to='/' active={{ value: false }}>
              Whitepaper
            </LinkMobileMenuTop>
            <LinkMobileMenuTop to='/' active={{ value: false }}>
              App
            </LinkMobileMenuTop>
          </MobileMenuTop>
          <MobileMenuBottom>
            <a href='https://evm.evmos.org/' target='_blank' rel='noreferrer'>
              <img src={evmos} alt='evmos' />
              <div>
                <h5>Evmos Explorer</h5>
                <span>Cosmos explorer</span>
              </div>
            </a>
            <a
              href='https://docs.evmos.org/validators/overview.html'
              target='_blank'
              rel='noreferrer'>
              <img src={validators} alt='validators' />
              <div>
                <h5>Validators</h5>
                <span>Validator Details</span>
              </div>
            </a>
            <Button
              text='Connect Wallet'
              color='red'
              prop_styles={{ margin: "30px 0 0 " }}
              active='false'
            />
          </MobileMenuBottom>
        </MobileMenuContent>
      </div>
    </MobileMenuWrapper>
  );
};
